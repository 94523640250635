@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  padding: 10px;
}

